import $ from 'jquery';

const $doc = $(document);
const $html = $('html');
const $mobileNav = $('.js-mobile-nav');
const $hamburger = $('.js-hamburger');

function showMobileNav(event) {
  console.log('=>(mobile-nav.js:9) event', event);
  event.preventDefault();
  $mobileNav.toggleClass('is-active');
  $html.toggleClass('fixed');
  $hamburger.toggleClass('is-active');
  if ($mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true');
  } else {
    $hamburger.attr('aria-expanded', 'false');
  }
}

function toggleArrow() {
  $(this).parent().find('a').toggleClass('rotate-arrow');
}

export default function initMobileNav() {
  $doc.on('click', '.js-hamburger', showMobileNav);
  $doc.on('click', '.dropdown-toggle', toggleArrow);
  $doc.on('keydown', function (event) {
    if ($mobileNav.hasClass('is-active') && (event.key == 'Escape')) {
      $hamburger.attr('aria-expanded', 'false');
      $mobileNav.toggleClass('is-active');
      $hamburger.toggleClass('is-active');
    }
  });
  $doc.on('focusin', function (event) {
    var $target = $(event.target);
    if (!$target.closest('.js-header-wrapper').length) {
      if ($mobileNav.hasClass('is-active')) {
        $hamburger.attr('aria-expanded', 'false');
        $mobileNav.toggleClass('is-active');
        $hamburger.toggleClass('is-active');
      }
    }
  });
}
